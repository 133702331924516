import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArrowLeftCircle, ArrowRightCircle, MessageCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';

import { TestimonialsData, TestimonialsDataRoot} from '../types/TestimonialsData';
import "./testimonials.css";

const Testimonials = () => {
    const { t } = useTranslation();
    const [testimonialsData, setTestimonialsData] = useState<TestimonialsData[] | undefined>(undefined);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = React.useState(false);
    const testimonialsUrl = "/api/partner/testimonials.json";
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const [langue, setlangue] = React.useState("en");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedItem, setSelectedItem] = useState<TestimonialsData | undefined>(undefined);

    useEffect(() => {
        const preferredLang = localStorage.getItem("lang") ?? navigator.language?.split("-")[0] ?? "en";
        setlangue(preferredLang);
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(testimonialsUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                
                const data: TestimonialsDataRoot = await response.json();
                if (langue == "fr") {
                    setTestimonialsData(data.data_fr);
                }
                if (langue == "en") {
                    setTestimonialsData(data.data_en);
                }
                if (langue == "es") {
                    setTestimonialsData(data.data_es);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } catch (err) { }
        };

        fetchData();

        const updateItemsPerPage = () => {
            if (window.innerWidth < 768) {
                setItemsPerPage(1);
            } else if (window.innerWidth < 992) {
                setItemsPerPage(2);
            } else {
                setItemsPerPage(3);
            }
        };

        updateItemsPerPage();
        window.addEventListener('resize', updateItemsPerPage);

        return () => window.removeEventListener('resize', updateItemsPerPage);
    }, [langue, t]);

    const nextTestimonials = () => {
        if (testimonialsData && currentIndex + itemsPerPage < (testimonialsData.length + 1)) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const prevTestimonials = () => {
        if (currentIndex - itemsPerPage >= 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };

    const currentTestimonials = testimonialsData ? testimonialsData.slice(currentIndex, currentIndex + itemsPerPage) : [];
    const columns = currentTestimonials.map((ttm, index) => (
        <Col xs={12} sm={12} md={6} lg={4} key={index} onClick={() => { setSelectedItem(ttm); handleShow(); }}>
            <div className="testimonial">
                <MessageCircle size={42} className="icon-content" />
                <div className="message-content">
                    <div className="text-content">
                        <p className="message">"{ttm.message}"</p>
                    </div>
                </div>
                <div className="name-content">
                    <p className="name" dangerouslySetInnerHTML={{ __html: `<strong>${ttm.name}</strong>, ${ttm.job}` }} />
                </div>
            </div>
        </Col>
    ));

    if (testimonialsData && currentIndex + itemsPerPage >= testimonialsData.length && currentTestimonials.length < itemsPerPage) {
        columns.push(
            <Col xs={12} sm={12} md={6} lg={4} key="manual">
                <div className="testimonial flowly">
                    <div className="message-content">
                        <div className="text-content">
                            <p className="message" dangerouslySetInnerHTML={{ __html: `<strong>${t('Pages.Society.ContactUs')}</strong>` }} />
                        </div>
                    </div>
                    <div className="name-content">
                        <p className="name" dangerouslySetInnerHTML={{ __html: `<strong>${t('Pages.Society.Flowly')}</strong>` }} />
                    </div>
                </div>
            </Col>
        );
    }
    return (
        <div className="testimonials-container">
            <button onClick={prevTestimonials} disabled={currentIndex === 0}>
                <ArrowLeftCircle size={42} />
            </button>
            <div className="testimonials">
                <Row>
                    {!loading && columns}
                </Row>
            </div>
            <button onClick={nextTestimonials} disabled={!testimonialsData || currentIndex + itemsPerPage >= testimonialsData.length}>
                <ArrowRightCircle size={42} />
            </button>
            <Modal className='testimonial-modal' size='lg' show={show} onHide={handleClose} centered>
                <div className='content'>
                    <p>{selectedItem?.message}<br></br><br></br><strong>{selectedItem?.name}</strong>, {selectedItem?.job}</p>
                    
                </div>
            </Modal>
        </div>
    );
};

export default Testimonials;
